<template>
  <div class="anquan">
    <div ref="header"><Header :bg="bg" :num="num"></Header></div>
    <div class="toubu">
      <div class="title">城市安全解决方案</div>
      <div class="more">构建一横N纵的智慧城市解决方案，用数据点亮城市生活</div>
    </div>
    <div class="content">
      <div class="left" ref="xiding">
        <div class="nav shuiwu-nav">
          <el-collapse accordion v-model="activeName">
            <el-collapse-item
              v-for="(item, index) in navlist"
              :key="index"
              :name="index + ''"
            >
              <template slot="title">
                <div class="name">
                  {{ item.name }}
                </div>
                <i class="el-icon-caret-right"></i>
              </template>
              <div class="box">
                <div class="box-son" v-for="(v, i) in item.son" :key="i">
                  {{ v.name }}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div
          class="miaoshu"
          v-for="(item, index) in navlist"
          :key="index"
          v-show="activeName == item.type"
        >
          <div class="miaoshu-title">{{ item.title }}</div>
          <div class="biaoqianbox">
            <div class="some" v-for="(v, i) in item.biaoqian" :key="i">
              {{ v.name }}
            </div>
          </div>
        </div>
      </div>

      <div class="right" ref="right">
        <div class="daohang">
          <div class="weizhi">你的位置：</div>
          <div class="mingxi">
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              class="mianbaoxie"
            >
              <el-breadcrumb-item :to="{ path: '/index' }"
                >首页</el-breadcrumb-item
              >
              <el-breadcrumb-item :to="{ path: '/solutionCenter' }"
                >方案中心</el-breadcrumb-item
              >
              <el-breadcrumb-item class="active">
                城市安全监测方案</el-breadcrumb-item
              >
            </el-breadcrumb>
          </div>
        </div>

        <Zhihuianquan></Zhihuianquan>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer.vue";
import Zhihuianquan from "../components/components/zhihuianquan.vue";
export default {
  components: {
    Header,
    Footer,
    Zhihuianquan,
  },
  data() {
    return {
      activeName: "0",
      type: 0,
      navlist: [
        {
          name: "城市安全解决方案",
          type: 0,
          title: "城市安全解决方案",
          biaoqian: [
            { name: "方案介绍" },
            { name: "方案架构" },
            { name: "方案主要构成模块介绍" },
            { name: "方案优势" },
          ],
          son: [
            { name: "地质灾害监测预警" },
            { name: "道桥健康监测预警" },
            { name: "水、土气监测预警" },
            { name: "城市管网监测预警" },
            { name: "应急管理决策支持" },
          ],
        },
        // {
        //   name: "城市安全解决方案",
        //   type: 1,
        //   son: [
        //     { name: "智慧供水解决方案" },
        //     { name: "智慧排水解决方案" },
        //     { name: "二次供水解决方案" },
        //     { name: "防汛解决方案" },
        //     { name: "河湖解决方案" },
        //   ],
        //   title: "物联网总线平台I-Ching",
        //   biaoqian: [
        //     { name: "背景需求" },
        //     { name: "产品概述" },
        //     { name: "技术架构" },
        //     { name: "应用架构" },
        //     { name: "系统特点" },
        //     { name: "运行环境" },
        //     { name: "经典场景" },
        //   ],
        // },
        // {
        //   name: "智慧城市解决方案",
        //   type: 2,
        //   son: [
        //     { name: "智慧供水解决方案" },
        //     { name: "智慧排水解决方案" },
        //     { name: "二次供水解决方案" },
        //     { name: "防汛解决方案" },
        //     { name: "河湖解决方案" },
        //   ],
        //   title: "物联网总线平台I-Ching",
        //   biaoqian: [
        //     { name: "背景需求" },
        //     { name: "产品概述" },
        //     { name: "技术架构" },
        //     { name: "应用架构" },
        //     { name: "系统特点" },
        //     { name: "运行环境" },
        //     { name: "经典场景" },
        //   ],
        // },
        // {
        //   name: "智慧政务解决方案",
        //   type: 3,
        //   son: [
        //     { name: "智慧供水解决方案" },
        //     { name: "智慧排水解决方案" },
        //     { name: "二次供水解决方案" },
        //     { name: "防汛解决方案" },
        //     { name: "河湖解决方案" },
        //   ],
        //   title: "物联网总线平台I-Ching",
        //   biaoqian: [
        //     { name: "背景需求" },
        //     { name: "产品概述" },
        //     { name: "技术架构" },
        //     { name: "应用架构" },
        //     { name: "系统特点" },
        //     { name: "运行环境" },
        //     { name: "经典场景" },
        //   ],
        // },
      ],
      arr: [],
      bg: null,
      num: 0,
    };
  },
  created() {
    this.num = this.$route.params.num;
    // console.log("this.$route.params :>> ", this.$route.params.num);
  },
  methods: {
    scroll() {
      let scroll = document.body.scrollTop;

      //console.log("object :>> ", scroll);
      this.arr.push(scroll);
      // 判断导航栏显示位置
      if (this.arr.length > 2) {
        let a = [this.arr[this.arr.length - 1]];
        let b = [this.arr[this.arr.length - 2]];
        if (a - b >= 0) {
          this.$refs.header.style.position = "absolute";
          this.$refs.header.style.top = "0";
          this.$refs.header.style.left = "0";
          this.$refs.header.style.zIndex = "100";
          //console.log("往下滑");
          if (scroll >= 300) {
            this.$refs.xiding.style.position = "fixed";
            this.$refs.xiding.style.top = "0";
            this.$refs.xiding.style.left = "0";
            this.$refs.right.style.marginLeft = "41vw";
          }
        } else {
          //console.log("往上滑");
          if (scroll > 300) {
            this.$refs.header.style.position = "fixed";
            this.$refs.header.style.top = "0";
            this.$refs.header.style.left = "0";
            this.$refs.header.style.zIndex = "100";
            this.bg = "black";
          }
          if (scroll <= 300) {
            this.$refs.xiding.style.position = "relative";
            this.$refs.xiding.style.top = "0";
            this.$refs.xiding.style.left = "0";
            this.$refs.right.style.marginLeft = "5.21vw";
          }
        }
        if (scroll <= 280) {
          this.$refs.header.style.position = "absolute";
          this.$refs.header.style.top = "0";
          this.$refs.header.style.left = "0";
          this.$refs.header.style.zIndex = "100";
        }
      }
    },
  },
  mounted() {
    document.body.scrollTop = 0;
    window.addEventListener("scroll", this.scroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll, true);
  },
};
</script>

<style lang="scss" scoped>
.anquan {
  width: 1920px;

  .toubu {
    width: 100%;
    height: 300px;
    background: url("../../../assets/imgs/fangan/shuiwubg.png");
    background-size: 100% 100%;
    .title {
      width: 100%;
      text-align: center;
      font-size: 36px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 24px;
      color: #ffffff;
      position: relative;
      top: 140px;
    }
    .more {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: normal;
      line-height: 22px;
      color: #ffffff;
      position: relative;
      top: 170px;
    }
  }
  .content {
    width: 100%;
    display: flex;
    .left {
      display: flex;

      .nav {
        width: 300px;
        height: 660px;
        background: rgba(255, 255, 255, 0.39);
        box-shadow: 2px 0px 6px rgba(184, 184, 184, 0.16);
        opacity: 1;
        padding-top: 96px;
        .navbox {
          display: flex;
          flex-direction: column;
          .navson {
            cursor: pointer;
            height: 40px;
            // display: flex;
            // align-items: center;
            text-align: right;
            font-size: 14px;
            font-family: Alibaba PuHuiTi;
            font-weight: normal;
            line-height: 40px;
            color: #777777;
            //margin-bottom: 20px;
            padding: 0 16px;
            position: relative;
            .em {
              position: absolute;
              top: 0;
              right: -2px;
              width: 2px;
              height: 100%;
              background-color: rgba(68, 112, 255, 1);
              opacity: 0;
            }
          }
          .active {
            font-size: 14px;
            font-family: Alibaba PuHuiTi;
            font-weight: normal;
            color: #181818;
            .em {
              opacity: 1;
            }
          }
        }
      }
      .miaoshu {
        margin-left: 70px;
        width: 316px;
        padding-top: 96px;
        .miaoshu-title {
          height: 75px;
          border-bottom: 1px solid rgba(232, 232, 232, 1);
          font-size: 18px;
          font-family: Alibaba PuHuiTi;
          font-weight: normal;
          line-height: 25px;
          color: #181818;
        }
        .biaoqianbox {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          //  justify-content: space-between;
          margin-top: 25px;
          .some {
            min-width: 78px;
            height: 26px;
            padding: 3px 10px;
            background-color: rgba(247, 248, 250, 1);
            font-size: 14px;
            font-family: Alibaba PuHuiTi Xi;
            font-weight: normal;
            line-height: 20px;
            color: #888888;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 14px;
            margin-right: 14px;
          }
        }
      }
    }
    .right {
      width: 870px;
      margin-left: 100px;
      position: relative;

      .daohang {
        // position: absolute;
        // top: 0;
        // right: 0px;
        height: 96px;
        // padding: 50px 260px;
        display: flex;
        //flex-direction: column;
        align-items: center;
        justify-content: end;
        font-family: Alibaba PuHuiTi Xi;
        .weizhi {
          font-size: 12px;
          font-weight: 400;
          color: rgba(196, 196, 196, 1);
        }
      }
    }
  }
}
</style>